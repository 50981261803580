$(document).ready(function(){

  $("input[type='tel']").mask("+7 (999) 999-9999");


  $('.steps .img-sel').on('click', function(event) {
    event.preventDefault();
    $(this).toggleClass('img-check');
  });

  step1();
  step2();
  step3();
  step4();
  step5();
 moveNext();
 movePrev();



  $('.check-block .checkbox').on('change', function() {
    if ($(this).is(':checked')) {
      $(this).parents('label').addClass('check');
    } else {
      $(this).parents('label').removeClass('check');
    }
  });

  $('.field input').on('click', function() {
    $(this).parents('.check-sel').find('label .checkbox').trigger('click');
  });

  $('#show-form').on('click', function(event) {
    event.preventDefault();
    $('#quest-area').fadeIn();
  });

  $('#quest-area .fa-close').on('click', function(event) {
    event.preventDefault();
    $('#quest-area').fadeOut();
  });


});//ready


function step1() {
 $('.stp1').each(function () {
   $('.stp1 .img-sel').on('click', function () {
     if ($('.img-check').length == 0) {
       $('.btn-next').addClass('noactive');
       $(this).parents('.steps').removeClass('done');
     } else {
       $('.btn-next').removeClass('noactive');
       $(this).parents('.steps').addClass('done');
     }
   });
   $('.stp1 label').on('change', function () {
     var labCheck = $('.stp1 label.check').length;
     if (labCheck == 0) {
       $('.btn-next').addClass('noactive');
       $(this).parents('.steps').removeClass('done');
     } else {1
       $('.btn-next').removeClass('noactive');
       $(this).parents('.steps').addClass('done');
     }
   });
 });
}

function step2(){
 $('.stp2 label').on('change', function() {
   var labCheck = $('.stp2 label.check').length;
   if (labCheck == 0) {
     $('.btn-next').addClass('noactive');
     $(this).parents('.steps').removeClass('done');
   }else{
     $('.btn-next').removeClass('noactive');
     $(this).parents('.steps').addClass('done');
   }
 });
}

function step3(){
 $('.stp3').each(function() {
   $('.stp3 .img-sel').on('click', function() {
     if ($('.img-check').length == 0) {
       $('.btn-next').addClass('noactive');
       $(this).parents('.steps').removeClass('done');
     }
     else{
       $('.btn-next').removeClass('noactive');
       $(this).parents('.steps').addClass('done');
     }
   });
 });
}

function step4(){
  $('.stp4 label').on('change', function() {
   var labCheck = $('.stp4 label.check').length;
   if (labCheck == 0) {
     $('.btn-next').addClass('noactive');
     $(this).parents('.steps').removeClass('done');
   }else{
     $('.btn-next').removeClass('noactive');
     $(this).parents('.steps').addClass('done');
   }
 });
}

function step5(){
 $('.stp5 label').on('change', function() {
   var labCheck = $('.stp5 label.check').length;
   if (labCheck == 0) {
     $('.btn-next').addClass('noactive');
     $(this).parents('.steps').removeClass('done');
   }else{
     $('.btn-next').removeClass('noactive');
     $(this).parents('.steps').addClass('done');
   }
 });
}

function moveNext(){
 $('.btn-next').on('click', function(event) {
   var sumBlocks = $('.steps').length - 2;
   var dataId = $(this).attr('data-id');
   var curentId = $('.quest-title.active').attr('data-id');
    var nextId = $('.quest-title.active').next('.quest-title').attr('data-id');
    var perCent = (100/sumBlocks)*curentId;

    $('.btn-next').addClass('noactive');

    $('.qt'+curentId).removeClass('active');
    $('.qt'+nextId).addClass('active');
   $('.stp'+curentId).removeClass('active');
   $('.stp'+nextId).addClass('active');

   $(this).parents('.step-button').removeClass('btn-step'+curentId).addClass('btn-step'+nextId);
   $('.progress > span').css('width', +perCent+'%');
   $('.progress__label > span').text(perCent+'%');
   $('.btn-back').removeClass('noactive');
   event.preventDefault();

   if(nextId > 5){
     $('.step-button, .progress-bar, .tit-area').css('display', 'none');
     $('#quest-area').addClass('full-width');
   }
   /*$('.download-btn').on('click', function(event) {
     $('.quest-title.qt6').removeClass('active');
     $('.steps.stp6').removeClass('active');
     $('.quest-title.qt7').addClass('active');
     $('.steps.stp7').addClass('active');
     event.preventDefault();
   });*/

  });
}


function movePrev(){
 $('.btn-back').on('click', function(event) {
   var sumBlocks = $('.steps').length - 2;
   var dataId = $(this).attr('data-id');
   var curentId = $('.quest-title.active').attr('data-id');
    var prevtId = $('.quest-title.active').prev('.quest-title').attr('data-id');
    var perCent = (100/sumBlocks)*prevtId - (100/sumBlocks);

    $('.btn-next').removeClass('noactive');

    $('.qt'+curentId).removeClass('active');
    $('.qt'+prevtId).addClass('active');
   $('.stp'+curentId).removeClass('active');
   $('.stp'+prevtId).addClass('active');

   $(this).parents('.step-button').removeClass('btn-step'+curentId).addClass('btn-step'+prevtId);
   $('.progress > span').css('width', +perCent+'%');
   $('.progress__label > span').text(perCent+'%');

   if(prevtId == 1){
     $('.btn-back').addClass('noactive');
   }

   event.preventDefault();
  });
}