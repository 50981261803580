/*
* Send form
*/
import swal from "sweetalert2";

$(document).ready(function(){
	$('.download-btn').click(function(e){
		e.preventDefault();
		var name = $(this).parents('.form').find('[name="name"]').val(),
			phone = $(this).parents('.form').find('[name="phone"]').val(),
			utm_content = $('body').find('[name="utm_content"]').val(),
			utm_term = $('body').find('[name="utm_term"]').val(),
			utm_campaign = $('body').find('[name="utm_campaign"]').val(),
			utm_source = $('body').find('[name="utm_source"]').val(),
			utm_medium = $('body').find('[name="utm_medium"]').val(),
			message = '';

		if(name.length != 0){
			message += '<b>Имя:</b> '+name+'<br/>';
		}
		if(phone.length === 17){
			message += '<b>Телефон:</b> '+phone+'<br/>';
		}else{
			swal.fire({
			  type: 'error',
			  title: 'Введите корректный номер телефона'
			})
			return false;
		}

		var stp1 = new Array();
		$('.stp1 .check').each(function(){
			stp1.push($(this).find('.label').text());
		});
    stp1 = stp1.join(', ');
    var title = '';
    $('.quest-title qt1').each(function(){
			title = $(this).text();
		});
		if(stp1.length > 0) message += '<b>'+title+'(шаг 1):</b> ' + stp1 + '<br/>';

		var stp2 = new Array();
		$('.stp2 .check').each(function(){
			stp2.push($(this).find('.label').text());
		});
    stp2 = stp2.join(', ');
    title = $('.quest-title .qt2');
		if(stp2.length > 0) message += '<b>'+title.first().text()+'(шаг 2):</b> ' + stp2 + '<br/>';

		var stp3 = new Array();
		$('.stp3 .img-check').each(function(){
			stp3.push($(this).find('.img-about').text());
		});
    stp3 = stp3.join(', ');
    title = $('.quest-title .qt3');
		if(stp3.length > 0) message += '<b>'+title.first().text()+'(шаг 3):</b> ' + stp3 + '<br/>';

		var stp4 = new Array();
		$('.stp4 .check').each(function(){
			stp4.push($(this).find('.label').text());
		});
    stp4 = stp4.join(', ');
    title = $('.quest-title .qt4');
		if(stp4.length > 0) message += '<b>'+title.first().text()+'(шаг 4):</b> ' + stp4 + '<br/>';

		var stp5 = new Array();
		$('.stp5 .check').each(function(){
			stp5.push($(this).find('.label').text());
		});
    stp5 = stp5.join(', ');
    title = $('.quest-title .qt5');
		if(stp5.length > 0) message += '<b>'+title.first().text()+'(шаг 5):</b> ' + stp5 + '<br/>';

		$.ajax({
			url: 'lead',
			type: 'post',
			data: $("#forma").serialize()+"&message="+message
			}).done(function(data) {
					reachGoal('lead');
			}).fail(function(data) {
				swal({ title: "",   text: "Не удалось отправить данные. Попробуйте снова.",   type: "error",   confirmButtonText: "ОK", confirmButtonColor: "#CD0117" });
              console.log('fail '+data);
            });
		//$(this)[0].reset();
		$('.quest-title.qt6').removeClass('active');
		$('.steps.stp6').removeClass('active');
		$('.quest-title.qt7').addClass('active');
		$('.steps.stp7').addClass('active');
		return false;
	});
});
